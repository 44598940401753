import React from "react"
import { CardColumns } from "reactstrap"
import { StaticQuery, graphql } from "gatsby"
import ImageCardItem from "../ImageCardItem"

export default function ImageCard() {
  return (
    <StaticQuery
      query={graphql`
        query AboutImageQuery {
          allFile(filter: { sourceInstanceName: { eq: "about-images" } }) {
            nodes {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `}
      render={data => (
        <section id="about-card image-card">
          <CardColumns>
            {data.allFile.nodes.map(image => (
              <ImageCardItem
                imagesUrl={image.childImageSharp.fluid}
                imagesAlt="About Card Image"
                imageUrl={image.childImageSharp.fluid}
                imageAlt="About Card Image"
              />
            ))}
          </CardColumns>
        </section>
      )}
    />
  )
}

import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

export default function ImageCard() {
  return (
    <StaticQuery
      query={graphql`
        {
          file(relativePath: { eq: "about.png" }) {
            childImageSharp {
              fluid {
                src
                srcSet
                base64
                aspectRatio
                sizes
              }
            }
            id
          }
        }
      `}
      render={data => <Img fluid={data.file.childImageSharp.fluid} />}
    />
  )
}

import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import ImageCard from "../components/about/ImageCard"
import AboutImage from "../components/about/about-image"

const About = () => {
  return (
    <Layout>
      <SEO
        title="About Us"
        keywords="ribsusabbq, ribs usa, ribs usa bbq, ribs, usa, bbq, best bbq near me, baby back ribs, beef ribs near me, best beef ribs near me, bbq chicken near me"
      />
      <PageTitle title="About Us" subtitle="“Where American Eats Ribs”" />
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pt-4 pt-lg-0 donate-content">
              <h3 className="adam title">
                Come enjoy some memorable BBQ with us.
              </h3>
              <br />
              <p>
                Ribs U.S.A. is a moderately priced family restaurant featuring
                high quality barbecued foods. In our relaxed atmosphere guests
                enjoy complimentary peanuts and tossing the shells on the floor,
                while sipping beer, wine, or sodas. When it comes to the food,
                we are the best. The meat falls off the bones of our famous baby
                back ribs and the big meaty beef ribs are out of this world. The
                menu includes chicken, seafood, salads and vegetarian dishes. We
                can accommodate large parties in the restaurant and have an
                extensive take-out menu. <br />
                <br /> <b>Specialties:</b> You'll find "BBQ" at RIBS USA in our
                atmosphere with unlimited complimentary peanuts,the meat falls
                off the bones of our famous Baby Back Ribs and the Big Meaty
                Beef Ribs are just out of this world..... Our menu includes Baby
                Back Ribs, Beef Ribs, Rib Tips, TriTip, Chicken, Shrimp,
                Sausages, Sandwiches, Hot Wings, Burgers and Salads this are
                just a few of our Specialties we make at RIBS USA. <br />
                <br /> <b>History:</b> Established in 1988. Ribs usa has been at
                this location for over 35 years.
              </p>
            </div>
            <div className="col-lg-6">
              <AboutImage />
            </div>
          </div>
          <div className="a-card">
            <ImageCard />
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default About

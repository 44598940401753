/* eslint-disable */
import React, { useState, Fragment } from "react"
import { Modal, Card } from "reactstrap"
import Img from "gatsby-image"

const ImageCardItem = props => {
  const { className } = props

  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <Fragment>
      <div onClick={toggle}>
        <Card className="show-image" key="about-image" inverse>
          <Img
            className="card-img-top"
            alt={props.imagesAlt}
            fluid={props.imagesUrl}
            objectFit="cover"
          />
        </Card>
      </div>
      <Modal size="lg" isOpen={modal} toggle={toggle} className={className}>
        <span
          className="close-gallery"
          color="secondary"
          onClick={toggle}
        ></span>
        <Img alt={props.imageAlt} fluid={props.imageUrl} />
      </Modal>
    </Fragment>
  )
}

export default ImageCardItem
